import {
    ApiReactiveService,
    type ApiServiceUseFetchOptions
} from '@composable-api/service/api.reactive-service'
import type { ApiServiceFetchOptions } from '@composable-api/service/api.service'
import type { ApiModel } from '@composable-api/api.model'
import { ProductVariationModel } from '../models/product-variation.model'

class ProductVariationsApiReactiveService<M extends ApiModel> extends ApiReactiveService<M> {
    useGet<T extends boolean>(options?: ApiServiceUseFetchOptions<M, T>) {
        return this.useFetch({
            ...options,
            method: 'GET',
        })
    }

    get(options?: ApiServiceFetchOptions<M>) {
        return this.fetch({
            ...options,
            method: 'GET',
        })
    }
}

export default function useProductVariationsApiService() {
    return new ProductVariationsApiReactiveService({
        endpoint: '/product-variations',
    }, ProductVariationModel)
}
